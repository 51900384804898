<template>
  <div
    class="v4-contact-container"
    :style="{
      height: $bowserMobile ? `${$screenHeight}px` : '100vh'
    }"
  >
    <div class="page__title scrollObj">contact us</div>
    <div class="main-content-box contactObj scrollObj">
      <div class="content__container">
        <div class="content__item scrollObj">
          <p class="item__title">深圳 Shenzhen</p>
          <p class="marginTop-1">深圳市福田保税区黄槐道三号深福保科技工业园B座三楼</p>
          <p>
            3/F, Building B, Shenfubao Industrial Park, No.3 Huanghuai Avenue, Futian Free Trade
            Zone, Shenzhen City, Guangdong Province 518038 P.R.China
          </p>
          <template v-if="$bowserMobile">
            <p class="marginTop-1">
              TEL_(0755) 3305 1333<span style="display:inline-block;padding:0 12px;">|</span
              >FAX_(0755) 3305 1335
            </p>
          </template>
          <template v-else>
            <p class="marginTop-1">TEL_(0755) 3305 1333</p>
            <p>FAX_(0755) 3305 1335</p>
          </template>
          <p
            :class="{
              'marginTop-1': !$bowserMobile
            }"
          >
            BUSINESS商务咨询_<a mailto="marketing@blvd.com.cn">marketing@blvd.com.cn</a>
          </p>
          <p>HR人力资源_<a mailto="hr@blvd.com.cn">hr@blvd.com.cn</a></p>
          <p>PRESS媒体_<a mailto="branding@blvd.com.cn">branding@blvd.com.cn</a></p>
        </div>
        <div class="content__item scrollObj">
          <p class="item__title">北京 Beijing·青岛 Qingdao·苏州 Suzhou</p>
          <p class="marginTop-1">TEL_(0755) 3305 1333</p>
          <p>FAX_(0755) 3305 1335</p>
        </div>
        <div class="content__item scrollObj">
          <p class="sp-en">social:</p>
          <ul class="item__social-container">
            <li class="social__item">
              <img class="normal wx" src="@/assets/images/demo4/icon-wx.svg" alt="" />
              <img class="hover" src="@/assets/images/qr.jpg" alt="" />
            </li>
            <li class="social__item">
              <img class="normal sina" src="@/assets/images/demo4/icon-sina.svg" alt="" />
            </li>
            <li class="social__item">
              <img class="normal ins" src="@/assets/images/demo4/icon-ins.svg" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'v4-page-contact',
  computed: {
    ...mapState(['$bowserMobile']),
  },
};
</script>

<style></style>
